import FileCopyOutlinedIcon from "@mui/icons-material/FileCopy";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutline";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToReg";
import PendingOutlinedIcon from "@mui/icons-material/Pending";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export const cards = (tasks) =>{
  
  
  
  return[
  {
    title: "Tasks",
    color: "#CAC0E2FF",
    icon: <FileCopyOutlinedIcon sx={{ color: "#553F89FF" }} />,
    valueLabel: tasks?.total,
  },
  {
    title: "In Progress",
    color: "#379AE6FF",
    icon: <DriveFileRenameOutlineOutlinedIcon sx={{ color: "#FFFFFF" }} />,
    valueLabel: tasks?.inProgress,
  },
  {
    title: "Completed",
    color: "#1DD75BFF",
    icon: <HowToRegOutlinedIcon sx={{ color: "#0A4D20FF" }} />,
    valueLabel: tasks?.completed,
  },
  {
    title: "Behind Schedule",
    color: "#EFB034FF",
    icon: <PendingOutlinedIcon sx={{ color: "#5D4108FF" }} />,
    valueLabel: tasks?.behindSchedule
  },
  {
    title: "Total Hours",
    color: "#991c1c",
    icon: <AccessTimeIcon sx={{ color: "#5D4108FF" }} />,
    valueLabel: tasks?.hours
  },
];}
