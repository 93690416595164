import React from 'react';
import { DashboardProvider } from '../DashboardContext'; // Import the DashboardProvider
import EmployeeDashboard from './EmployeeDashboard';

function Dashboard() {
  return (
    <DashboardProvider>
      <EmployeeDashboard />
    </DashboardProvider>
  );
}

export default Dashboard;