import React, { useState } from "react";
import VideoModal from "./VideoPlayer";
import GradButton from "../../../components/Button/GradButton/GradButton";
import Navbar from "../../../components/HomeNavbar";
import Circle from "../../../assets/Circle.svg";
import { Button } from "@mui/material";
import "./LandingPage.css";

function LandingPage() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="lp-container">
      <div class="hero-section">
        <div className="hero">
          <h4>End to End</h4>

          <div className="title">
            <h1 className="digital">Digital</h1>
            <h1 className="transformation">Transformation</h1>
          </div>
          <p>
            Empower your transformation with our centralized data powerhouse.
            Streamline operations, foster collaboration, and ignite innovation
            seamlessly.
          </p>
          <Button
            className="video-button"
            variant="contained"
            sx={{
              // position: "absolute",
              // marginLeft: "15rem",
              zIndex: 1000,
              // width: "12rem",
              borderRadius: "1.5rem",
              color: "#fff",
              fontWeight: "bold",
              textTransform: "none",
              fontSize: "18px",
              // boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
              padding: "5px 18px 5px 18px;", /*Top padding 0 bcs font has gap at the top*/
              background: "linear-gradient(135deg,#C69EEE, #8A14FE, #2B094C)"
              // transition: "transform 0.2s, background-color 0.2s",
              // "&:hover": {
            //   backgroundColor: "#1565c0",
            //   transform: "scale(1.05)",
            // },
          }}
          onClick={handleOpen}
          >
          <span>Watch Video: Storage Tank API-650</span>
        </Button>
        {/* <GradButton className="grd-btn" text="Watch Video: Storage Tank API-650" onClick={handleOpen}/> */}
      </div>
      <div className="hero-img">
        <img src={Circle} alt="Circle" />
      </div>
    </div>

    <VideoModal open={open} handleClose={handleClose} link={""}/>
    </div >
  );
}

export default LandingPage;
