import { Box } from "@mui/material";
import { formatDate } from "../../../../utils/formatDate";

export const table4d5dColumns = [
    // {
    //     accessorKey: "task", // Task name from the model
    //     header: "Task Name",
    //     size: 200,
    //     enableEditing: false
    // },
    {
        accessorKey: "startDate", // Planned start date
        header: "Planned Start",
        size: 10,
        muiEditTextFieldProps: {
            type: 'date',
        },
        Cell: ({ cell }) => (
            <>{formatDate(cell?.getValue())}</>
        )
    },
    {
        accessorKey: "targetDate", // Planned end date
        header: "Planned Finish",
        size: 10,
        muiEditTextFieldProps: {
            type: 'date',
        },
        Cell: ({ cell }) => (
            <>{formatDate(cell?.getValue())}</>
        )
    },
    {
        accessorKey: "actualStart", // Actual start date
        header: "Actual Start",
        size: 10,
        enableEditing: false,
        muiEditTextFieldProps: {
            type: 'date',
        },
        Cell: ({ cell }) => (
            <>{formatDate(cell?.getValue())}</>
        )
    },
    {
        accessorKey: "actualEnd", // Actual end date
        header: "Actual Finish",
        size: 10,
        enableEditing: false,
        muiEditTextFieldProps: {
            type: 'date',
        },
        Cell: ({ cell }) => (
            <>{formatDate(cell?.getValue())}</>
        )
    },
    {
        accessorKey: "progress", // Progress from the model
        header: "Progress",
        muiEditTextFieldProps: {
            type: 'number',
        },
        size: 10,
        Cell: ({ cell }) => (
            <>
                {cell.getValue()}
            </>
        )
    },
    {
        accessorKey: "manhours", // Manhours from the model
        header: "M",
        size: 10,
        muiEditTextFieldProps: {
            type: 'number',
        },
    },
    {
        accessorKey: "estimatedTime", 
        header: "Est. Time",
        size: 10,
        muiEditTextFieldProps: {
            type: 'number',
        },
        Cell: ({ cell }) => (
            <>{cell.getValue()}</>
        ),
    },
];
