import { React, useState, useContext } from "react";
import { Link, useNavigate, Navigate } from "react-router-dom";
import sideImg from "../../../images/auth_form_eng.jpg";
import { AuthContext } from "../../../context/authContext";
import paths from "../../../api/paths.api";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useMsal } from "@azure/msal-react";

import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import microsoft_icon from "../../../images/microsoft_icon.png";
import { verifyAccessToken } from "./api";

const StaffLogin = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const { getAccessToken, setAuth, accessToken } = useContext(AuthContext);

  const loginRequest = {
    scopes: [
      `api://${process.env.REACT_APP_AZURE_AD_SERVER_CLIENT_ID}/specialScope`,
    ],
    // scopes: ["api://3eca0ec5-8973-4200-9146-599fec0e6b00/specialScope"],
  };

  const handleLogin = () => {
    instance
      .loginPopup({
        ...loginRequest,
      })
      .then(async (res) => {
        await getAccessToken().then(async (res) => {
          await verifyAccessToken({ token: accessToken })
            .then((response) => {
              const role = response.data.role;
              setAuth({ role });
              // localStorage.setItem("xvhuuroh", role);
            })
            .catch((err) => {
              console.log(err);
            });
        });
        if (res.account?.name || res.account?.username) {
          navigate(paths.myProjects);
        }
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Card
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          bgcolor: "White",
          p: 5,
          pb: 8,
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Welcome to ChinookDT
        </Typography>
        <Typography variant="p2">Members only login</Typography>
        <br />
        <Button
          type="submit"
          fullWidth
          variant="outlined"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
          }}
          onClick={handleLogin}
        >
          <img style={{ height: "30px", width: "30px" }} src={microsoft_icon} />
          <Typography variant="body1" style={{ marginLeft: "10px" }}>
            Sign In With Microsoft
          </Typography>
        </Button>
      </Card>
    </Container>
  );
};

export default StaffLogin;
