import { MaterialReactTable } from "material-react-table";
import React, { useState, useContext } from "react";
import { table4d5dColumns } from "./columns";
import { Box, Stack, Typography, Autocomplete, TextField } from "@mui/material";
import NonEditableTable from "../../../../components/Tables/NonEditableTable";
import TimelineComponent from './TimelineComponent'
import { AuthContext } from "../../../../context/authContext";
import { getAllProjects ,getSchedule} from "./api";
import InformalCards from "./InformalCards";
// import { da } from "date-fns/locale";

const Project4d = () => {
  const { accessToken } = useContext(AuthContext);

  const [projects, setProjects] = useState([]); // Holds all project data
  const [selectedProject, setSelectedProject] = useState(null); // Holds the selected project
  const [rows, setRows] = useState([]); // Holds the table data
  const [filteredTasks,setFilteredTasks] = useState(null)

 
  React.useEffect(() => {
    async function fetchProjects() {
      try {
        const projs = await getAllProjects(accessToken);
        setProjects(projs);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    }
    fetchProjects();
  }, [accessToken]);
  function filterTasks(tasks,totalHours) {
    const currentDate = new Date();
  
    // Filter tasks based on the conditions for each category
    const inProgress = tasks.filter(task => task.progress < 100 && new Date(task.targetDate) > currentDate);
    const completed = tasks.filter(task => task.progress === 100);
    const behindSchedule = tasks.filter(task => task.progress < 100 && new Date(task.targetDate) <= currentDate);
  
   setFilteredTasks({total:tasks.length,inProgress:inProgress.length,completed:completed.length,behindSchedule:behindSchedule.length,hours:totalHours})
  }

  const handleProjectSelect = async (event, newValue) => {
    setSelectedProject(newValue);
    if (newValue) {
console.log('value',newValue);
   const data = await getSchedule(newValue.id,accessToken)
   console.log('dataaa',data);
   setRows(data.data.tasks)
   filterTasks(data.data.tasks,data.data.totalHoursSpent)
    }
  };

  return (
    <Box p={2}>
      <Stack
       sx={{ paddingBottom: "2rem" }}
       direction={"column"}
       justifyContent="space-between"
       height={"10rem"}
      >
      <Stack
        sx={{ paddingBottom: "2rem" }}
        direction={"column"}
        justifyContent="space-between"
        height={"20rem"}
        alignItems="center"
      >
        <h2>Schedule</h2>

        {/* Autocomplete for selecting a project */}
        <Autocomplete
          id="project-select"
          options={projects}
          getOptionLabel={(option) => option.projectName || ""}
          value={selectedProject}
          onChange={handleProjectSelect}
          renderInput={(params) => (
            <TextField {...params} label="Select Project" variant="outlined" />
          )}
          sx={{ width: 300 }}
        />

      </Stack>
          <InformalCards tasks={filteredTasks}/>  

      {/* {selectedProject ? ( */}
        <Stack direction={"row"}>
          <Box width={"50vw"}>
            <NonEditableTable columns={table4d5dColumns} data={rows} />
          </Box>
          <Box width={"40vw"}>
          <TimelineComponent  rows={rows} />

          </Box>
        </Stack>
      {/* ) : (
        <Typography>Please Select a Project</Typography>
      )} */}
      </Stack>
    </Box>
  );
};

export default Project4d;

