import React from 'react';
import { Gantt } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import Typography from "@mui/material/Typography";
import { Box } from '@mui/material';

const TimelineComponent = ({ rows }) => {
    const formattedData = rows.map(row => {
        const startDate = row?.startDate ? new Date(row?.startDate) : new Date();
        const targetDate = row?.targetDate ? new Date(row?.targetDate) : new Date();
        const actualEnd = row?.actualEnd ? new Date(row?.actualEnd) : null;
        const progress = row?.progress || 0;
        const calcTime = row?.calcTime || 0;
        const estimatedTime = row?.estimatedTime || 0;
        const currentDate = new Date();
    
        let backgroundColor = "rgb(50, 150, 230)"; // Default color
    
        // // Green for completed tasks
        // if (progress === 100 && calcTime <= estimatedTime) {
        //     backgroundColor = 'rgb(144, 238, 144)';
        // }
    
        // // Red for behind schedule (progress < 100 and targetDate is in the past)
        // else if (progress < 100 && targetDate < currentDate) {
        //     backgroundColor = 'rgb(255, 182, 193)';
        // }
    
        // // Yellow for tasks where calcTime > estimatedTime, in progress, and not behind schedule
        // else if (progress < 100 && calcTime > estimatedTime && targetDate >= currentDate) {
        //     backgroundColor = 'rgb(255, 255, 153)';
        // }
        // // console.log('colorr',backgroundColor);
        
    
        return {
            id: row.id,
            name: row?.task || "", // Assuming 'task' is the name of the task
            start: startDate,
            end: actualEnd ? actualEnd : targetDate, // Use actualEnd if it exists, otherwise use targetDate
            progress: progress,
            type: 'task',
            styles: {
                backgroundColor: backgroundColor, // Apply the background color based on the conditions
            }
        };
    });
    

    return (
        <Box sx={{ width: "inherit" }}>
            <Box sx={{ backgroundColor: "#FFFF", p: 1 }}>
                <Typography variant="h6" color="text.secondary" sx={{ pb: 1, textAlign: "center" }}>
                    Timeline
                </Typography >
                {
                    formattedData?.length > 0 ? (
                        <Gantt
                            tasks={formattedData}
                            viewMode={"Month"}
                            columnWidth={150}
                            listCellWidth={""}
                            rowHeight={49.95}
                            headerHeight={55}
                            preStepsCount={0}
                        />
                    ) : (
                        <Typography>No Data Available</Typography>
                    )
                }
            </Box>
        </Box>
    );
};

export default TimelineComponent;
