import { Box, Button } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import React from "react";

const NonEditableTable = ({
  columns,
  data,
  additionalProps,
  topForm,
}) => {
  return (
    <Box
      sx={{ "& td": { p: 1, borderRight: "1px solid #ddd", overflow: "auto" } }}
    >
      <MaterialReactTable
        columns={columns}
        data={data}
        editDisplayMode="row"
        enableEditing={false}
        enableSorting={false}
        enableColumnActions={false}
          // Disable toolbar features (full-screen, search, etc.)
          enableGlobalFilter={false} // Disables search bar
          enableDensityToggle={false} // Disables row density options
          enableColumnFilters={false} // Disables column filters
          enableFullScreenToggle={false} // Disables full-screen mode
          enableHiding={false}
          enableToolbar={false}
          muiTopToolbarProps={{ sx: { padding: 0 }}}
        // onEditingRowSave={(exitEditingMode, row, values) => handleSaveRow(exitEditingMode, row, values)}
        positionActionsColumn="last"
        muiTableHeadCellProps={({ cell }) => ({
          sx: {
            borderRight: "1px solid rgba(224,224,224,1)",
            backgroundColor: "hsla(250, 86%, 97%, 1)",
            // pt: 2,
            height: "2rem",
            lineHeight: "1.3rem",
          },
        })}
        muiTableBodyCellProps={({ cell }) => ({
          sx: {
            height: "1.5  rem", // Enforcing row height of 2rem
            lineHeight: "2.05rem", // Ensuring the text inside rows is vertically centered
          },
        })}
        defaultColumn={{
          minSize: 1, 
          maxSize: 900, 
        }}
        muiTableBodyRowProps={({ row }) => {
          const currentDate = new Date()
          const targetDate = new Date(row.original.targetDate);
          const progress = row.original.progress;
          const calcTime = row.original.calcTime;
          const estimatedTime = row.original.estimatedTime;

          // Apply row coloring logic
          let backgroundColor = "inherit"; // Default color

          // Green for completed tasks
          if (progress === 100) {
            backgroundColor = 'rgba(0, 128, 0, 0.1)';
          }

          // Red for behind schedule
          else if (progress < 100 && targetDate < currentDate) {
            backgroundColor = 'rgba(255, 0, 0, 0.2)';
          }

          // Yellow for tasks where calcTime > estimatedTime, in progress, and not behind schedule
          else if (progress < 100 && calcTime > estimatedTime && targetDate >= currentDate) {
            backgroundColor = 'rgba(255, 255, 0, 0.2)'; // Yellow background
          }

          return {
            sx: {
              backgroundColor: backgroundColor, // Set row background color based on conditions
            },
          };
        }}
        initialState={{
          pagination: { pageSize: 100 }, // Start with showing 50 rows per page
        }}
        renderTopToolbarCustomActions={() => (topForm ? topForm : <></>)}
        {...additionalProps}
      />
    </Box>
  );
};

export default NonEditableTable;
