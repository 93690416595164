import axios from "../../../../api/axios";
import { tokenHeader } from "../../../../api/tokenHeader";

export const getSchedule = async (projectId,accessToken) => {
  const { data } = await axios.get("project/schedule/" + projectId,tokenHeader(accessToken));
  return data;
};


export const getAllProjects = async ( accessToken ) => {
  const { data } = await axios.get("/project/all", tokenHeader(accessToken))
  return data
}